<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Reported User moderation</div>
      </div>
      <div class="flex-grow-1">
        <div class="filters-wrapper d-flex flex-column flex-md-row py-4">
          <div class="date-wrapper d-flex flex-column flex-md-row align-center">
            <Datepicker
              :namespace="namespace"
              :initial-value="sevenDaysAgo"
              :from-date="true"
            />
            <Datepicker
              :namespace="namespace"
              :initial-value="currentDate"
              :to-date="true"
            />
          </div>
          <div class="moderation-types-wrapper mt-4">
            <v-select
              v-model="selectedReason"
              :items="reasons"
              item-value="name"
              outlined
              :attach="'#reportReasonsUsersMenu'"
              hide-details
              label="Reason"
              placeholder="reason"
              @change="changeReason"
            ></v-select>
            <div id="reportReasonsUsersMenu"></div>
          </div>
        </div>
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="namespace"
          :route-name="routeName"
          :id="idValue"
          :custom-action-btn="customActionBtn"
          :show-expand="true"
          @custom-action="viewUserInfo"
        />
      </div>
      <t-dialog-wrapper
        :show="userInfoModal"
        width="600"
        @close-dialog="closeUserInfoModal"
      >
        <v-card>
          <v-card-title class="flex-column align-stretch px-0 pb-0">
            <div class="ml-4 mb-2">User info</div>
            <v-divider></v-divider>
          </v-card-title>
          <v-card-text v-if="!approvingUser">
            <div v-if="!userInfo" class="text-h6 mt-4">Not available</div>
            <div v-else>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Reported User Username: </span
                >{{ userInfo.user.username || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Reported User ID: </span
                >{{ userInfo.user.id || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold mb-5">Reasons: </span>
                <v-row
                  class="report-row ml-2 my-2"
                  v-for="(report, index) in userInfo.reports"
                  :key="`report-${index}`"
                >
                  - <span>{{ report.reason.reportName }}</span>
                </v-row>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <div class="mt-2">
              Select one or more reasons for approving this report:
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="reasons-wrapper mt-4">
                <v-select
                  v-model="actionReasons"
                  :items="reasons"
                  ref="reasonsSelect"
                  outlined
                  label="Reason"
                  placeholder="Reason"
                  multiple
                  chips
                  :rules="rules.required"
                  deletable-chips
                ></v-select>
              </div>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-5 actions-wrapper">
            <v-btn
              large
              color="#da192f"
              class="white--text"
              @click="approveReport(userInfo)"
              :loading="loadingApprove"
            >
              Approve
            </v-btn>
            <v-btn
              v-if="!approvingUser"
              large
              :loading="loadingReject"
              @click="rejectReport(userInfo)"
            >
              Reject
            </v-btn>
            <v-btn v-else large @click="goBack()"> Back </v-btn>
          </v-card-actions>
        </v-card>
      </t-dialog-wrapper>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ReportedUserModeration",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    Datepicker: () => import("@/components/Core/Datepicker"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
  },
  props: {
    reasons: {
      Type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loadingApprove: false,
      loadingReject: false,
      routeName: "reported-user-moderation",
      namespace: "adminReportedUserModeration",
      idValue: "user.id",
      customActionBtn: {
        text: "View",
        icon: "mdi-card-search-outline",
      },
      userInfoModal: false,
      userInfo: null,
      headers: [
        {
          text: "Reported User Username",
          align: "left",
          sortable: false,
          value: "user.username",
        },
        {
          text: "Reported User ID",
          align: "left",
          sortable: false,
          value: "user.id",
        },
        {
          text: "Report reason",
          align: "left",
          sortable: false,
          value: "reasonsText",
        },
        {
          text: "View user",
          align: "center",
          sortable: false,
          value: "customAction",
        },
      ],
      dateFrom: "",
      dateTo: "",
      selectedReason: {
        value: null,
        text: null,
      },
      actionReasons: [],
      approvingUser: false,
      valid: true,
      rules: {
        required: [(v) => v.length > 0 || "Select at least one reason"],
      },
    };
  },
  async mounted() {
    this.selectedReason.value = this.options.reportReason;
  },
  computed: {
    ...mapGetters({
      options: "adminReportedUserModeration/pagination",
      items: "adminReportedUserModeration/items",
    }),
    sevenDaysAgo() {
      const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      return sevenDaysAgo.toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    currentDate() {
      const newDate = new Date();
      return newDate.toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
  },
  methods: {
    async changeReason() {
      this.$store.commit(
        "adminReportedUserModeration/setReason",
        this.selectedReason === "All" ? "" : this.selectedReason
      );
    },
    viewUserInfo(item) {
      this.userInfo = item;
      this.userInfoModal = true;
    },
    closeUserInfoModal() {
      this.userInfoModal = false;
      this.userInfo = null;
      this.actionReasons = [];
      this.approvingUser = false;
    },
    getCurrentReasons(reports) {
      return reports.map((report) => {
        return report.reason.reportCode;
      });
    },
    goBack() {
      this.actionReasons = [];
      this.approvingUser = false;
    },
    async approveReport(userInfo) {
      if (!this.approvingUser) {
        this.approvingUser = true;
        return;
      }
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loadingApprove = true;
      const item = {
        userId: userInfo.user.id,
        reasonCodes: this.actionReasons,
      };
      await this.$store.dispatch(
        "adminReportedUserModeration/approveReport",
        item
      );
      this.loadingApprove = false;
      this.closeUserInfoModal();
    },
    async rejectReport(userInfo) {
      this.loadingReject = true;
      await this.$store.dispatch(
        "adminReportedUserModeration/rejectReport",
        userInfo.user.id
      );
      this.loadingReject = false;
      this.closeUserInfoModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.moderation-types-wrapper {
  position: relative;
}

#reportReasonsUsersMenu {
  position: absolute;
  bottom: 0px;
}
.filters-wrapper {
  padding: 12px;
}
</style>
